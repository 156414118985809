
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import FAQ from 'components/FAQ'
import LocationsBlock from 'components/LocationsBlock'
import { baseUrl } from 'components/Meta'
import { TinaContextProvider } from 'lib/tina'
import { TestLocation, TinaContent } from 'lib/types'
import { getRequest, isServer, randomString } from 'lib/utils'
import { GetStaticProps, NextPage } from 'next'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import Blog from '../components/Blog'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Referrals from '../components/Referrals'
import { getAuthToken } from 'lib/authToken'

const Home: NextPage<Props> = ({ locations, contents }) => {
  const router = useRouter()
  const { t } = useTranslation('common')

  useEffect(() => {
    if (!isServer) {
      const subDomain = window.location.host.split('.')[0]
      if (
        locations.some(
          (l) => l.region_name.toLowerCase() === subDomain.toLowerCase()
        )
      ) {
        window.location.href = `${baseUrl}/${subDomain}`
      }
    }

    if (
      router.asPath.indexOf('/#testresult') !== -1 ||
      router.asPath.indexOf('/#consent') !== -1 ||
      router.asPath.indexOf('/#ticket') !== -1
    ) {
      router.push(router.asPath.replace('#', ''))
    }
  }, [isServer])

  return (
    <TinaContextProvider content={contents}>
      <Layout>
        <Hero />
        <div className='px-4 mx-auto max-w-7xl'>
          <Referrals />
          <Blog />
          <span className='mb-4 text-sm text-gray-400'>{t('timeNotice')}</span>
          <LocationsBlock locations={locations} />
          <FAQ />
        </div>
      </Layout>
    </TinaContextProvider>
  )
}

export default Home
interface Props {
  locations: TestLocation[]
  contents: TinaContent[]
}

 const _getStaticProps: GetStaticProps = async (context) => {
  try {
    const authToken = await getAuthToken({})

    const locations = await getRequest({
      endPoint: `/test_locations`,
      authToken,
    })

    let content = []

    try {
      const contentResponse = await fetch(
        `${process.env.NEXT_PUBLIC_BASE_URL}/api/content`,
        {
          method: 'GET',
        }
      ).then((res) => res.json())

      content = contentResponse.values
    } catch (error) {}

    return {
      props: {
        locations: locations.test_locations.filter(
          (l) => Boolean(l.region_name) && l.business_partner === 0
        ),
        contents: content,
      },
      revalidate: 60,
    }
  } catch (error) {
    console.log('error index', error)
  }
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  