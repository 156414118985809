import { TestLocation } from 'lib/types'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import React, { FC, useState } from 'react'

const LocationBlock = ({ index, name, sortedLocations }) => {
  const [isOpen, setIsOpen] = useState(index === 0)
  const [imageSrc, setImageSrc] = useState(`/regions/${name}.png`)

  return (
    <Link key={name} href={`/${name.toLowerCase()}`}>
      <a onClick={() => setIsOpen(!isOpen)}>
        <div className='flex flex-col items-center p-4 m-4 transition-all border border-gray-300 rounded-lg shadow-lg hover:shadow-xl'>
          <span className='mb-2 text-2xl font-semibold text-primary-500'>
            {name}
          </span>
          <img
            alt={`${name} Karte`}
            onError={(e) =>
              setImageSrc(
                `${process.env.NEXT_PUBLIC_API_URL}/web/test_locations/map?lat=${sortedLocations[name][0].lat}&lon=${sortedLocations[name][0].lng}`
              )
            }
            className='rounded-lg'
            height={92}
            width={300}
            src={imageSrc}
          />
        </div>
      </a>
    </Link>
  )
}

const LocationsBlock: FC<Props> = ({ locations }) => {
  const { t } = useTranslation('common')

  const sortedLocations = locations.reduce((prev, next) => {
    if (!prev[next.region_name]) {
      return { ...prev, [next.region_name]: [next] }
    }

    return {
      ...prev,
      [next.region_name]: [...(prev as any)[next.region_name], next],
    }
  }, {})

  return (
    <section className='lg:mb-16'>
      <h3 className='mb-8 text-4xl font-bold'>{t('ourStations')}</h3>
      <div className='flex flex-wrap items-center justify-center max-h-[1500px] overflow-x-auto'>
        {Object.keys(sortedLocations)
          .filter((name) => !['testone', 'DiscoveryDock'].includes(name))
          .map((name, index) => (
            <LocationBlock
              key={index}
              name={name}
              index={index}
              sortedLocations={sortedLocations}
            />
          ))}
      </div>
    </section>
  )
}

export default LocationsBlock

interface Props {
  locations: TestLocation[]
}
